
import abi from "./contract/GlobalPool.json";

import Buy from "./component/buy";
import Buy1 from "./component/buy1";
import Buy2 from "./component/buy2";
import Buy3 from "./component/buy3";
import Buy4 from "./component/buy4";
import Buy5 from "./component/buy5";

import "./css/style.css";
import "./css/bootstrap.min.css";

import "./logo.png";

import "./161.png";
import "./162.png";
import "./163.png";
import "./164.png";
import "./165.png";
import "./166.png";

import "./168.png";
import "./169.png";
import "./1610.png";
import "./1611.png";
import "./1612.png";
import "./1614.png";
import "./copy.png";


import { useState, useEffect } from "react";
import { ethers } from "ethers";

function App() {

  const [state, setState] = useState({
    provider: null,
    signer: null,
    contract: null,
  });
  const [account, setAccount] = useState("None");
  const[b,setB]=useState("None");
  const[direct,setDirect]=useState("None");
  const[directs,setDirects]=useState("None");
  const[level,setLevel]=useState("None");
  const[auto,setAuto]=useState("None");
  const[auto1,setAuto1]=useState("None");
  const[auto2,setAuto2]=useState("None");
  const[auto3,setAuto3]=useState("None");
  const[auto4,setAuto4]=useState("None");
  const[auto5,setAuto5]=useState("None");
  const[auto6,setAuto6]=useState("None");
  const[auto7,setAuto7]=useState("None");
  const[bonus,setBonus]=useState("None");
  const[bonus1,setBonus1]=useState("None");
  const[pack,setpackage]=useState("None");
  const[refer,setrefer]=useState("None");
const [tasks,setTasks] = useState([]);
const [total,setTotal]=useState("None");
const [req,setreq]=useState("None");
const [tt,settt]=useState("None");
const[dd,setDD]=useState("None");
const[referlink,setreflink]=useState("None");
const[bonanza,setBonanza]=useState("None");
const[bonanza1,setBonanza1]=useState("None");
const[booster,setBooster]=useState("None");
const[au,setAu]=useState("None");
const[au1,setAu1]=useState("None");
const[au2,setAu2]=useState("None");
const[au3,setAu3]=useState("None");

const[wid,setwid]=useState("None");


  useEffect(() => {
    const connectWallet = async () => {
      const contractAddress = "0x2374251dd743c5286d93030cc6b72d972d425422";
      const contractABI = abi.abi;
      const searchParams = new URLSearchParams(window.location.search);
      const myParam = searchParams.get('ref');
      console.log(myParam);
      
      
      document.querySelector("#name").value=myParam;
  if(myParam == null)
  {
    document.querySelector("#name").value="0xe66de568E20997B4038eA2De4b5528817C573CdB";
  }
      try {
        const { ethereum } = window;
        document.querySelector("#test").value = "Upgrade USDT 30";
        if (ethereum) {
          const account = await ethereum.request({
            method: "eth_requestAccounts",
           
          });
         
          window.ethereum.on("chainChanged", () => {
            window.location.reload();
          });

          window.ethereum.on("accountsChanged", () => {
            
            window.location.reload();
          });
          const provider = new ethers.providers.Web3Provider(ethereum);
          try {
            const selectedNetwork = await provider.getNetwork();
          
            if (selectedNetwork.chainId !== 56) {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x38' }], // Binance Smart Chain Mainnet chainId is 97 in decimal, which is 0x61 in hex
              });
              console.log("You have switched to the Binance network");
            } else {
              console.log("You are already on the Binance network");
            }
          } catch (switchError) {
            // The network has not been added to MetaMask
            if (switchError.code === 4902) {
              console.log("Please add the Binance network to MetaMask");
            } else {
              console.log("Cannot switch to the network");
            }
          }
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            contractAddress,
            contractABI,
            signer
          );
          setAccount(account);
          setState({ provider, signer, contract });
          document.querySelector("#us").value  = account[0];
          let alltasks = await contract.getMemos(account[0]);
          setTasks(alltasks);
          const b =Number(alltasks[2]);
          const refer = (alltasks[1]);
         setrefer(refer);
         document.querySelector("#hid").value  = (alltasks[1]);
         const direct =Number(alltasks[2]);
         setDirect(direct);
         const directs =  Number(alltasks[22]);
         setDirects(directs);
         const au1 = Number(alltasks[7]) ;
         setAu1(au1);
         
        
         const level = Number(alltasks[3]) ;
         setLevel(level);
         const auto = Number(alltasks[4]);
         setAuto(auto);
         const auto1 = Number(alltasks[11]);
         setAuto1(auto1);
         const auto2 = Number(alltasks[12]);
         setAuto2(auto2);
         
         const auto3 = Number(alltasks[13]);
         setAuto3(auto3);
         
         const auto4 = Number(alltasks[14]);
         setAuto4(auto4);
         const auto5 = Number(alltasks[15]);
         setAuto5(auto5);
         const auto6 = Number(alltasks[16]);
         setAuto6(auto6);
         const auto7 = Number(alltasks[17]);
         setAuto7(auto7);
         

         
         
         
         
         
         const bonus = Number(alltasks[5]);
         setBonus(bonus);
         const bonus1 = Number(alltasks[21]);
         setBonus1(bonus1);
         const total = Number(alltasks[10]);
         setTotal(total);

        
         const au = auto  + auto1 + auto2 + auto3 + auto4 + auto5 + auto6 + auto7;
         setAu(au);
       
         
         const pack = Number(alltasks[6]);
         document.querySelector("#pp").value  = Number(alltasks[6]);
         
    
    setpackage(pack);
    const bonanza = Number(alltasks[7]);
         setBonanza(bonanza);
         const bonanza1 = Number(alltasks[7]);
         setBonanza1(bonanza1);
         const booster = Number(alltasks[18]);
         setBooster(booster);
    const dd = new Date(alltasks[8].timestamp * 1000).toLocaleString();
    setDD(dd);
         setB(b);
         const referlink = 'https://universalcrowd.io/?ref='+ account;
         setreflink(referlink);
         const au2 =Number(alltasks[3]) ;
         setAu2(au2);
         if(au2 > 10000000)
         {
      const au3 = (115792089237316195423570985008687907853269984665640564039457584007913129639936 - au2) + Number(alltasks[7])/100;
          setAu3(au3);
          const tt = directs  + bonus + auto + total + auto1 + auto2 + auto3 + auto4 + auto5 + auto6 + auto7 + au3;
          settt(tt);
         }
         else
         {
          const au3 =  Number(alltasks[3]) + Number(alltasks[7])/100;
          setAu3(au3);
          const tt = directs  + bonus + auto + total + auto1 + auto2 + auto3 + auto4 + auto5 + auto6 + auto7 + au3;
          settt(tt);
         }
        
         document.querySelector("#link").value =referlink;
    if(pack==30)
    { 
      document.querySelector("#test").value = "Upgrade USDT 60";
    
    }
    else if(pack==90)
    { 
      document.querySelector("#test").value = "Upgrade USDT 120";
    
    }
    else if(pack ==210)
    {
      document.querySelector("#test").value = "Upgrade USDT 240";
    }
    else if(pack ==450)
    {
      
      document.querySelector("#test").value = "Upgrade USDT 480";
    }
    else if(pack ==930)
    {
      document.querySelector("#test").value = "Upgrade USDT 960";
    }
    
    else if(pack ==1890)
    {
      document.querySelector("#test").value = "Upgrade USDT 1920";
    }
    else if(pack ==3810)
    {
      document.querySelector("#test").value = "Upgrade USDT 3840";
    }
    else if(pack ==7650)
    {

      document.querySelector("#test").value = "All Packages Purchased";
    }
    
    console.log(pack);
    
     
        } else {
          alert("Please install metamask");
        }
      } catch (error) {
        console.log(error);
      }
    };
    
     
    
    
    connectWallet();
    
  }, []);

 
  // console.log(state);
        
       
  return (
   
<div style={{backgroundColor:"black"}}>
<input type="hidden" id ="link"></input>
    <input type="hidden" id ="hid"></input>
    <input type="hidden" id ="us"></input>
    <input type="hidden" id ="pp"></input>

    <div class="page-container" >
 
      
            
      
              
               <table width="100%">
               <tr>
  <td style={{width: "100px",color: "white",textAlign :"center",verticalAlign:"center", 
}} >
  <img src ="logo.png" width="200px" height="150px" />
  </td></tr>        
<tr>
  <td style={{width: "100px",color: "white",textAlign :"center",verticalAlign:"center", 
}} ><h2> 100% Fully Decenterlized <br></br>AutoPool System.</h2></td></tr>
         </table>      
           
             
         <div class="clearfix"> </div>	

         <br></br>  
<table width="100%">
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>TOTAL PACKAGE <br></br> PURCHASED</h4>
<h5>  USDT {pack}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr><tr><td></td><td><br></br><br></br></td><td></td></tr>
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>MY DIRECT <br></br> TEAM</h4>
<h5>  USDT {direct}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr><tr><td></td><td><br></br><br></br></td><td></td></tr>
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>MY DIRECT <br></br> INCOME</h4>
<h5>  USDT {directs}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr><tr><td></td><td><br></br><br></br></td><td></td></tr>

<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>MY LEVEL <br></br> INCOME</h4>
<h5>  USDT {au3}

 </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr>
<tr><td></td><td><br></br><br></br></td><td></td></tr> 
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>MY AUTOPOOL <br></br> INCOME</h4>
<h5>  USDT {au}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr>

<tr><td></td><td><br></br><br></br></td><td></td></tr> 
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>MY BOOSTER <br></br> INCOME</h4>
<h5>  USDT {bonus}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr>
<tr><td></td><td><br></br><br></br></td><td></td></tr> 


<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>UPGRADE <br></br> INCOME</h4>

<h5>  USDT {total}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr>
<tr><td></td><td><br></br><br></br></td><td></td></tr>
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>MY AUTOPOOL - I <br></br> INCOME</h4>
<h5>  USDT {auto}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr><tr><td></td><td><br></br><br></br></td><td></td></tr>
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>MY AUTOPOOL - II <br></br> INCOME</h4>
<h5>  USDT {auto1}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr><tr><td></td><td><br></br><br></br></td><td></td></tr>
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>MY AUTOPOOL - III <br></br> INCOME</h4>
<h5>  USDT {auto2}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr><tr><td></td><td><br></br><br></br></td><td></td></tr>
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>MY AUTOPOOL - IV <br></br> INCOME</h4>
<h5>  USDT {auto3}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr><tr><td></td><td><br></br><br></br></td><td></td></tr>
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>MY AUTOPOOL - V <br></br> INCOME</h4>
<h5>  USDT {auto4}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr>
<tr><td></td><td><br></br><br></br></td><td></td></tr>
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>MY AUTOPOOL - VI <br></br> INCOME</h4>
<h5>  USDT {auto5}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr><tr><td></td><td><br></br><br></br></td><td></td></tr>
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>MY AUTOPOOL - VII <br></br> INCOME</h4>
<h5>  USDT {auto6}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr><tr><td></td><td><br></br><br></br></td><td></td></tr>
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>MY AUTOPOOL - VIII <br></br> INCOME</h4>
<h5>  USDT {auto7}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr>

<tr><td></td><td><br></br><br></br></td><td></td></tr>


<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>BOOSTER <br></br> AVAILABLE</h4>
<h5>   {booster}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr>
<tr><td></td><td><br></br><br></br></td><td></td></tr>
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>BOOSTER <br></br> PURCHASED</h4>
<h5>   {bonanza1}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr>
<tr><td></td><td><br></br><br></br></td><td></td></tr>
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>TOTAL <br></br> INCOME</h4>
<h5>   {tt}  </h5><br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr>
<tr><td></td><td><br></br><br></br></td><td></td></tr>

<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h4>PURCHASE <br></br> BOOSTER </h4>
                <h5>USDT - 100</h5>
								 <Buy5 state={state} />
<br></br> </td>
<td style={{width :"7%"}}><br></br></td>

</tr>
<tr><td></td><td><br></br><br></br></td><td></td></tr>

<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"92%",height :"100%", verticalAlign:"center", textAlign: "center",
color: "white",
}}>
  <br></br>
  <h5> <input type ="text" style={{backgroundColor: "#8029f2" , border :"0" } }id ="test"></input></h5>
                            <Buy state={state} /></td>
<td style={{width :"7%"}}><br></br></td>

</tr>
<tr><td></td><td><br></br><br></br></td><td></td></tr>

</table>

      <br></br>  

       
   

<table width="90%">
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"100%",height :"100%", verticalAlign:"center", textAlign: "left",
color: "white",
}}>
  <br></br>
  <h5>WELCOME TO UNIVERSAL CROWD : CONNECTED ACCOUNT - {account}</h5></td>
<td style={{width :"7%"}}><br></br></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"100%",height :"100%", verticalAlign:"center", textAlign: "left",
color: "white",
}}>
  <br></br>
  <h5>REFER LINK - {referlink} <button><img src ="copy.png" height={"25px"} onClick={() => {
  navigator.clipboard.writeText(referlink);}} /></button></h5></td>
<td style={{width :"7%"}}><br></br></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
  <td style={{width :"1%"}}><br></br></td>  
<td style={{ backgroundColor: "#8029f2",width :"100%",height :"100%", verticalAlign:"center", textAlign: "left",
color: "white",
}}>
  <br></br>
  <h5>MY REFER - {refer}</h5></td>
<td style={{width :"7%"}}><br></br></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
</table>

   

                          
                          
    
    <div class="clearfix"></div>
  

<div class="copyrights">
	 <p>© 2023 Universal Crowd. All Rights Reserved  </p>
</div>	

</div>
</div>


       

           
                   
	




     

  );
}

export default App;
